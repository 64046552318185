import React, {Component} from "react";
import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import {Editor} from '@toast-ui/react-editor';
import {Form, Icon} from "semantic-ui-react";
import axios from "axios";

class SysNoticeCreate extends Component {
  editorRef = React.createRef();

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  handleSave = async () => {
    const editorInstance = this.editorRef.current.getInstance()
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/system/notice`, {
        'title': this.state.title,
        'author': this.state.author,
        'content': editorInstance.getMarkdown()
      }, {withCredentials: true});
      alert('Success!');
      window.location.href = "/system/notice"
    } catch (e) {
      alert("Fail...");
    }
  }

  render() {
    return (
      <div>
        <h3 className='mb-6'>Create System Notice</h3>
        <Form>
          <Form.Input required label='Title' name='title' placeholder="title" onChange={this.handleChange}/>
          <Form.Input required label='Author' name='author' placeholder="author" onChange={this.handleChange}/>
          <Editor
            previewStyle="vertical"
            ref={this.editorRef}
          />
          <Form.Button className='mt-7' onClick={this.handleSave}><Icon name='save'/> Save</Form.Button>
        </Form>
      </div>
    )
  }
}

export default SysNoticeCreate;