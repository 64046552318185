import React, {Component} from 'react';
import {Button, Grid} from 'semantic-ui-react'
import axios from 'axios';
import {Link} from 'react-router-dom'

class MkCategoryHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: []
    }
  }

  async componentDidMount() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/market/category`, {withCredentials: true});
      this.setState({
        categories: response.data
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <div>
        <div>
          <Link to={'/market/category/create'}>
            <Button primary className='mb-7'>Create</Button>
          </Link>
        </div>

        <Grid doubling columns={4}>
          {
            this.state.categories.map((category, idx) =>
              <Grid.Column key={idx}>
                <Link to={`/market/category/update?id=${category.uuid}`}>
                  <div className='p-card hover-gray pd-2 has-text-centered'>
                    <p className='fs-3 mb-2'>{category.emoji}</p>
                    <p className='fs-1 fw-b color-black'>{category.name}</p>
                  </div>
                </Link>
              </Grid.Column>
            )
          }
        </Grid>
      </div>
    )
  }
}

export default MkCategoryHome;
