import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import {Container, Divider, Icon} from 'semantic-ui-react'
import MkCategoryHome from "./mk.category.home";
import MkCategoryCreate from "./mk.category.create";
import MkCategoryUpdate from "./mk.category.update";

class CategoryModule extends Component {

  render () {
    return (
      <Container>
        <h2 className='pv-5'><Icon name='tag'/> Market Category</h2>
        <Switch>
          <Route exact path={'/market/category'} component={MkCategoryHome}/>
          <Route exact path={'/market/category/create'} component={MkCategoryCreate}/>
          <Route exact path={'/market/category/update'} component={MkCategoryUpdate}/>
        </Switch>
      </Container>
    )
  }
}

export default CategoryModule;
