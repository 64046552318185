import React, {Component} from 'react'
import './App.css';
import Navbar from "./navbar/navbar";
import {Divider, Icon, Menu, Sidebar} from "semantic-ui-react";
import {Route, Switch, withRouter} from "react-router-dom";
import Footer from "./navbar/footer";
import SysNoticeModule from "./notice/notice.module";
import ChKeywordModule from "./keyword/keyword.module";
import CategoryModule from "./category/category.module";
import GroupModule from "./group/group.module";
import ImpersonateModule from "./impersonate/impersonate.module";
import UserContext from "./UserContext";
import axios from "axios";
import HomePage from "./home.page";

class App extends Component {
  state = {visible: false};

  async componentDidMount() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/auth/verifyToken`, {withCredentials: true});
      const user = res.data;

      this.setState({
        user: user
      });
    } catch (err) {
      console.log(err);
    }
  }

  open = () => {
    this.setState({visible: true})
  }

  goto = (loc) => {
    this.props.history.push(loc);
    this.setState({visible: false});
  }

  render() {
    return (
      <UserContext.Provider value={this.state.user}>
        {
          this.state.user && this.state.user.group.type === 'ADMIN' && this.state.user.email === 'admin@poapper.com' ?
            <div style={{height: 'inherit'}}>
              <Navbar openSidebar={this.open}/>
              <Sidebar
                animation='push'
                onHide={() => this.setState({visible: false})}
                vertical
                visible={this.state.visible}
                style={{justifyContent: 'center', display: 'flex', height: '100%', background: '#fff', zIndex: 5}}
              >
                <Menu secondary vertical className='pv-5' style={{marginTop: '65px'}}>
                  <Menu.Item
                    className='mb-5'
                    name='Group'
                    onClick={this.goto.bind(this, '/group/subtype')}
                  />
                  <Divider/>
                  <Menu.Header className='mb-5'>Channel</Menu.Header>
                  <Menu.Item
                    name='Keyword'
                    onClick={this.goto.bind(this, '/channel/keyword')}
                  />
                  <Divider/>
                  <Menu.Header className='mb-5'>Market</Menu.Header>
                  <Menu.Item
                    name='Category'
                    onClick={this.goto.bind(this, '/market/category')}
                  />
                  <Divider/>
                  <Menu.Item
                    name='Notice'
                    onClick={this.goto.bind(this, '/system/notice')}
                  />
                  <Divider/>
                  <Menu.Item
                    name='Impersonate'
                    onClick={this.goto.bind(this, '/impersonate')}
                  />
                  <Divider/>
                  <Menu.Item
                    onClick={() => {
                      window.location.href = 'https://poscossp.com';
                    }}
                  >
                    <Icon name='home'/> SSP Home
                  </Menu.Item>
                  <div className='pb-5'/>
                </Menu>
              </Sidebar>
              <Sidebar.Pushable>
                <Sidebar.Pusher dimmed={this.state.visible}>
                  <div style={{marginTop: '80px'}}>
                    <Route>
                      <Switch>
                        <Route exact path="/" component={HomePage}/>
                        <Route path="/system/notice" component={SysNoticeModule}/>
                        <Route path="/channel/keyword" component={ChKeywordModule}/>
                        <Route path="/market/category" component={CategoryModule}/>
                        <Route path="/group/subtype" component={GroupModule}/>
                        <Route path="/impersonate" component={ImpersonateModule}/>
                      </Switch>
                    </Route>
                    <Footer/>
                  </div>
                </Sidebar.Pusher>
              </Sidebar.Pushable>
            </div> :
            <div className='pd-5'>
              <h1>403</h1>
              <p className='fw-b'>You do not have permission on this page.</p>
            </div>
        }
      </UserContext.Provider>
    );
  }
}

export default withRouter(App)
