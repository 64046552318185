import React, {Component} from "react";
import {Form, Icon} from "semantic-ui-react";
import axios from "axios";

const typeOptions = [
  {
    key: 'VENTURE',
    text: 'VENTURE',
    value: 'VENTURE'
  },
  {
    key: 'PARTNER',
    text: 'PARTNER',
    value: 'PARTNER'
  },
  {
    key: 'POSCO',
    text: 'POSCO',
    value: 'POSCO'
  },
  {
    key: 'ADMIN',
    text: 'ADMIN',
    value: 'ADMIN'
  }
]

class GroupSubtypeCreate extends Component {
  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  handleSave = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/group/subtype`, {
        'id': this.state.id,
        'name': this.state.name,
        'description': this.state.description,
        'parent_type': this.state.parent_type
      }, {withCredentials: true});
      alert('Success!');
      window.location.href = "/group/subtype"
    } catch (e) {
      alert("Fail...");
    }
  }

  render() {
    return (
      <div>
        <h3 className='mb-6'>Create Group Subtype</h3>
        <Form>
          <Form.Input required label='Id' name='id' placeholder="id" onChange={this.handleChange}/>
          <Form.Input required label='Name' name='name' placeholder="name" onChange={this.handleChange}/>
          <Form.TextArea label='Description' name='description' placeholder="description" onChange={this.handleChange}/>
          <Form.Select required label='Type' name='parent_type' placeholder="type" options={typeOptions} onChange={this.handleChange}/>
          <Form.Button className='mt-7' onClick={this.handleSave}><Icon name='save'/> Save</Form.Button>
        </Form>
      </div>
    )
  }
}

export default GroupSubtypeCreate;