import React, { Component } from 'react'
import { Container, Dropdown, Icon, Image, Menu } from 'semantic-ui-react'
import MediaQuery from "react-responsive/src";
import { withRouter } from 'react-router-dom';

class Navbar extends Component {

  goto = (loc) => {
    this.props.history.push(loc);
  }

  render () {
    return (
      <div style={{borderBottom: '1px solid rgba(0,0,0,.1)', background: '#fff', position: 'fixed', width: '100%', top: 0, zIndex: 10}} className='pv-1'>
        <Container>
          <Menu secondary>
            <MediaQuery maxWidth={768}>
              <Menu.Item onClick={this.props.openSidebar}>
                <Icon name='sidebar' style={{margin: 0}}/>
              </Menu.Item>
            </MediaQuery>

            <Menu.Item className='nav-logo' style={{paddingLeft: 0, paddingRight: 0}} onClick={this.goto.bind(this, '/')}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <Image size='tiny' src={'/ssp-logo-blue.svg'}/>
                <p className='ml-5 fw-b'>System Admin</p>
              </div>
            </Menu.Item>

            <MediaQuery minWidth={768}>
              <Menu.Item
                position='right'
                name='Group'
                onClick={this.goto.bind(this, '/group/subtype')}
              />
              <Dropdown item text='Channel'>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={this.goto.bind(this, '/channel/keyword')}>Keyword</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown item text='Market'>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={this.goto.bind(this, '/market/category')}>Category</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Menu.Item
                name='Notice'
                onClick={this.goto.bind(this, '/system/notice')}
              />
              <Menu.Item
                  name='Impersonate'
                  onClick={this.goto.bind(this, '/impersonate')}
              />
              <Menu.Item
                position='right'
                onClick={() => {window.location.href = 'https://poscossp.com';}}
              >
                <Icon name='home'/> SSP Home
              </Menu.Item>
            </MediaQuery>
          </Menu>
        </Container>
      </div>
    )
  }
}

export default withRouter(Navbar)
