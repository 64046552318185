import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import {Container, Divider, Icon} from 'semantic-ui-react'
import ChKeywordHome from "./ch.keyword.home";
import ChKeywordUpdate from "./ch.keyword.update";
import ChKeywordCreate from "./ch.keyword.create";

class ChKeywordModule extends Component {

  render () {
    return (
      <Container>
        <h2 className='pv-5'><Icon name='keyboard'/> Channel Keyword</h2>
        <Switch>
          <Route exact path={'/channel/keyword'} component={ChKeywordHome}/>
          <Route exact path={'/channel/keyword/create'} component={ChKeywordCreate}/>
          <Route exact path={'/channel/keyword/update'} component={ChKeywordUpdate}/>
        </Switch>
      </Container>
    )
  }
}

export default ChKeywordModule;
