import React, { Component } from 'react'
import {Form, Icon} from 'semantic-ui-react'
import axios from 'axios'

class MkCategoryUpdate extends Component {
  constructor (props) {
    super(props)
    this.query = new URLSearchParams(this.props.location.search)
    this.state = {
      isLoading: true,
    }
  }

  async componentDidMount () {
    try {
      const id = this.query.get('id')
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/market/category/${id}`, {withCredentials: true})
      const res = response.data
      this.setState({
        isLoading: false,
        name: res.name,
        emoji: res.emoji
      })
    } catch (e) {

    }
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value,
    })
  }

  handleUpdate = async () => {
    try {
      console.log(this.query.get('id'));
      await axios.put(`${process.env.REACT_APP_API_URL}/market/category/${this.query.get('id')}`, {
        'name': this.state.name,
        'emoji': this.state.emoji
      }, {withCredentials: true});
      alert('Success!');
      window.location.href = "/market/category"
    } catch (e) {
      alert("Fail...");
    }
  }

  handleDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/market/category/${this.query.get('id')}`, {withCredentials: true});
      alert('Successfully deleted!')
      window.location.href = '/market/category'
    } catch (e) {
      alert('Fail...')
    }
  }

  render () {
    return (
      <div>
        <h3 className='mb-6'>Edit Category</h3>
        {
          (!this.state.isLoading) ?
            <Form>
              <Form.Input required label='Emoji' name='emoji' placeholder="emoji" onChange={this.handleChange} value={this.state.emoji}/>
              <Form.Input required label='Name' name='name' placeholder="name" onChange={this.handleChange} value={this.state.name}/>

              <Form.Group>
                <Form.Button className='mt-7' onClick={this.handleUpdate}><Icon name='edit'/> Edit</Form.Button>
                <Form.Button className='mt-7' onClick={this.handleDelete}
                             negative><Icon name='trash'/> Delete</Form.Button>
              </Form.Group>
            </Form>
            : null
        }
      </div>
    )
  }
}

export default MkCategoryUpdate;