import React, { Component } from 'react'
import {Button, Container, Divider, Form, Icon} from 'semantic-ui-react'

class ImpersonateModule extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: 'admin@poapper.com',
            password: '',
            target: '',
        }
    }

    handleChange = (e, data) => {
        this.setState({
            [data.name]: data.value
        });
    }

    impersonate = () => {
        window.location.href = `${process.env.REACT_APP_API_URL}/auth/impersonate?id=${this.state.id}&password=${this.state.password}&target=${this.state.target}`;
    }

    render () {
        return (
            <Container>
                <h2 className='pv-5'><Icon name='user secret'/> Impersonate</h2>
                <Form>
                    <Form.Input fluid required label='ID' name='id' placeholder='PoApper ID (Email)' value={this.state.id} onChange={this.handleChange}/>
                    <Form.Input fluid required label='Password' name='password' type='password'  placeholder='PoApper Password' value={this.state.password} onChange={this.handleChange}/>
                    <Form.Input fluid required label='Target Email' name='target' placeholder='Impersonate User ID (Email)' value={this.state.target} onChange={this.handleChange}/>
                    <Button className='mv-7' onClick={this.impersonate}>Impersonate</Button>
                </Form>
            </Container>
        )
    }
}

export default ImpersonateModule;
