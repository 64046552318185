import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import {Container, Divider, Icon} from 'semantic-ui-react'
import SysNoticeHome from "./sys.notice.home";
import SysNoticeCreate from "./sys.notice.create";
import SysNoticeUpdate from "./sys.notice.update";

class SysNoticeModule extends Component {

  render () {
    return (
      <Container>
        <h2 className='pv-5'><Icon name='bullhorn'/> SSP System Notice</h2>
        <Switch>
          <Route exact path={'/system/notice'} component={SysNoticeHome}/>
          <Route exact path={'/system/notice/create'} component={SysNoticeCreate}/>
          <Route exact path={'/system/notice/update'} component={SysNoticeUpdate}/>
        </Switch>
      </Container>
    )
  }
}

export default SysNoticeModule;
