import React, { Component } from 'react'
import 'codemirror/lib/codemirror.css'
import '@toast-ui/editor/dist/toastui-editor.css'
import { Editor } from '@toast-ui/react-editor'
import {Form, Icon} from 'semantic-ui-react'
import axios from 'axios'

class SysNoticeUpdate extends Component {
  constructor (props) {
    super(props)
    this.query = new URLSearchParams(this.props.location.search)
    this.state = {
      isLoading: true,
    }
  }

  editorRef = React.createRef()

  async componentDidMount () {
    try {
      const uuid = this.query.get('uuid')
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/system/notice/${uuid}`, {withCredentials: true})
      const existNotice = response.data
      this.setState({
        isLoading: false,
        title: existNotice.title,
        author: existNotice.author,
        content: existNotice.content,
      })
    } catch (e) {

    }
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value,
    })
  }

  handleUpdate = async () => {
    const editorInstance = this.editorRef.current.getInstance()
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/system/notice/${this.query.get('uuid')}`, {
          'title': this.state.title,
          'author': this.state.author,
          'content': editorInstance.getMarkdown(),
        }, {withCredentials: true})
      alert('Success!')
      window.location.href = '/system/notice'
    } catch (e) {
      alert('Fail...')
    }
  }

  handleDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/system/notice/${this.query.get('uuid')}`, {withCredentials: true});
      alert('Successfully deleted!')
      window.location.href = '/system/notice'
    } catch (e) {
      alert('Fail...')
    }
  }

  render () {
    return (
      <div>
        <h3 className='mb-6'>Edit System Notice</h3>
        {
          (!this.state.isLoading) ?
            <Form>
              <Form.Input required label='Title' name='title' placeholder="Title"
                          onChange={this.handleChange}
                          value={this.state.title}/>
              <Form.Input required label='Author' name='author' placeholder="author"
                          onChange={this.handleChange}
                          value={this.state.author}/>
              <Editor
                previewStyle="vertical"
                ref={this.editorRef} initialValue={this.state.content}
                hideModeSwitch={true}
              />
              <Form.Group>
                <Form.Button className='mt-7' onClick={this.handleUpdate}><Icon name='edit'/> Edit</Form.Button>
                <Form.Button className='mt-7' onClick={this.handleDelete}
                             negative><Icon name='trash'/> Delete</Form.Button>
              </Form.Group>
            </Form>
            : null
        }
      </div>
    )
  }
}

export default SysNoticeUpdate;