import React, {Component} from "react";
import {Form, Icon} from "semantic-ui-react";
import axios from "axios";

class ChKeywordCreate extends Component {
  state = { related_tags: [] }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  handleSave = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/keyword`, {
        'name': this.state.name,
        'related_tags': this.state.related_tags,
        'description': this.state.description,
        'icon_url': this.state.icon_url,
        'banner_url': this.state.banner_url,
        'color': this.state.color,
        'order': this.state.order
      }, {withCredentials: true});
      alert('Success!');
      window.location.href = "/channel/keyword"
    } catch (e) {
      alert("Fail...");
    }
  }

  render() {
    const defaultTags = [];
    const tagsLabel = (tags) => {
      const tag = [];
      for (const t of tags) {
        tag.push({
          key: t,
          text: t,
          value: t
        })
      }
      return tag.concat(defaultTags);
    }

    const renderLabel = (label) => ({
      content: `${label.text}`,
    })

    return (
      <div>
        <h3 className='mb-6'>Create Keyword</h3>
        <Form>
          <Form.Input required label='Name' name='name' placeholder="name" onChange={this.handleChange}/>
          <Form.Dropdown
            label='Related Tags'
            placeholder='related tags'
            fluid
            search
            selection
            multiple
            allowAdditions
            onAddItem={(event, data) => defaultTags.push({key: data.value, text: data.value, value: data.value})}
            options={tagsLabel(this.state.related_tags)}
            renderLabel={renderLabel}
            value={this.state.related_tags}
            name='related_tags'
            onChange={this.handleChange}
          />
          <Form.TextArea required label='Description' name='description' placeholder="description..." onChange={this.handleChange}/>
          <Form.Input required label='Icon Img' name='icon_url' placeholder="url" onChange={this.handleChange}/>
          <Form.Input required label='Banner Img' name='banner_url' placeholder="url" onChange={this.handleChange}/>
          <Form.Input required label='Color' name='color' placeholder="#005386" onChange={this.handleChange}/>
          <Form.Input required label='Order' name='order' placeholder="0" onChange={this.handleChange}/>
          <Form.Button className='mt-7' onClick={this.handleSave}><Icon name='save'/> Save</Form.Button>
        </Form>
      </div>
    )
  }
}

export default ChKeywordCreate;