import React, { Component } from 'react'
import {Container, Divider, Icon} from 'semantic-ui-react'

class HomePage extends Component {

  render () {
    return (
      <Container>
        <h1 className='pv-5'>Welcome to SSP System Admin!</h1>
        <p>Only for PoApper, Inc.</p>
      </Container>
    )
  }
}

export default HomePage;
