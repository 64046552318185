import React, { Component } from 'react'
import {Form, Icon} from 'semantic-ui-react'
import axios from 'axios'

const typeOptions = [
  {
    key: 'VENTURE',
    text: 'VENTURE',
    value: 'VENTURE'
  },
  {
    key: 'PARTNER',
    text: 'PARTNER',
    value: 'PARTNER'
  },
  {
    key: 'POSCO',
    text: 'POSCO',
    value: 'POSCO'
  },
  {
    key: 'ADMIN',
    text: 'ADMIN',
    value: 'ADMIN'
  }
]

class GroupSubtypeUpdate extends Component {
  constructor (props) {
    super(props)
    this.query = new URLSearchParams(this.props.location.search)
    this.state = {
      isLoading: true,
    }
  }

  async componentDidMount () {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/group/subtype/${this.query.get('id')}`, {withCredentials: true})
      const subtype = response.data
      this.setState({
        isLoading: false,
        name: subtype.name,
        description: subtype.description,
        parent_type: subtype.parent_type,
      })
    } catch (e) {

    }
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value,
    })
  }

  handleUpdate = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/group/subtype/${this.query.get('id')}`, {
          'name': this.state.name,
          'description': this.state.description,
        }, {withCredentials: true})
      alert('Success!')
      window.location.href = '/group/subtype'
    } catch (e) {
      alert('Fail...')
    }
  }

  handleDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/group/subtype/${this.query.get('id')}`, {withCredentials: true});
      alert('Successfully deleted!')
      window.location.href = '/group/subtype'
    } catch (e) {
      alert('Fail...')
    }
  }

  render () {
    return (
      <div>
        <h3 className='mb-6'>Edit Group Subtype</h3>
        {
          (!this.state.isLoading) ?
            <Form>
              <Form.Input disabled required label='Id' name='id' placeholder="id" value={this.query.get('id')}/>
              <Form.Input required label='Name' name='name' placeholder="name" onChange={this.handleChange} value={this.state.name}/>
              <Form.TextArea label='Description' name='description' placeholder="description" onChange={this.handleChange} value={this.state.description}/>
              <Form.Select disabled required label='Type' name='parent_type' placeholder="type" options={typeOptions} value={this.state.parent_type}/>
              <Form.Group>
                <Form.Button className='mt-7' onClick={this.handleUpdate}><Icon name='edit'/> Edit</Form.Button>
                <Form.Button className='mt-7' onClick={this.handleDelete}
                             negative><Icon name='trash'/> Delete</Form.Button>
              </Form.Group>
            </Form>
            : null
        }
      </div>
    )
  }
}

export default GroupSubtypeUpdate;