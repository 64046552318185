import React, { Component } from 'react'
import {Form, Icon} from 'semantic-ui-react'
import axios from 'axios'

class ChKeywordUpdate extends Component {
  constructor (props) {
    super(props)
    this.query = new URLSearchParams(this.props.location.search)
    this.state = {
      isLoading: true,
      related_tags: []
    }
  }

  async componentDidMount () {
    try {
      const name = this.query.get('name')
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/keyword?name=${encodeURI(name)}`, {withCredentials: true})
      const res = response.data
      this.setState({
        isLoading: false,
        name: res.name,
        related_tags: res.tags && res.tags.map(tag => tag.name),
        description: res.description,
        icon_url: res.icon_url,
        banner_url: res.banner_url,
        color: res.color,
        order: res.order
      })
    } catch (e) {

    }
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value,
    })
  }

  handleUpdate = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/keyword`, {
        'name': this.state.name,
        'related_tags': this.state.related_tags,
        'description': this.state.description,
        'icon_url': this.state.icon_url,
        'banner_url': this.state.banner_url,
        'color': this.state.color,
        'order': this.state.order
      }, {withCredentials: true});
      alert('Success!');
      window.location.href = "/channel/keyword"
    } catch (e) {
      alert("Fail...");
    }
  }

  handleDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/keyword?name=${encodeURI(this.query.get('name'))}`, {withCredentials: true});
      alert('Successfully deleted!')
      window.location.href = '/channel/keyword'
    } catch (e) {
      alert('Fail...')
    }
  }

  render () {
    const defaultTags = [];
    const tagsLabel = (tags) => {
      const tag = [];
      for (const t of tags) {
        tag.push({
          key: t,
          text: t,
          value: t
        })
      }
      return tag.concat(defaultTags);
    }

    const renderLabel = (label) => ({
      content: `${label.text}`,
    })

    return (
      <div>
        <h3 className='mb-6'>Edit Keyword</h3>
        {
          (!this.state.isLoading) ?
            <Form>
              <Form.Input disabled required label='Name' name='name' placeholder="name" value={this.state.name}/>
              <Form.Dropdown
                label='Related Tags'
                placeholder='related tags'
                fluid
                search
                selection
                multiple
                allowAdditions
                onAddItem={(event, data) => defaultTags.push({key: data.value, text: data.value, value: data.value})}
                options={tagsLabel(this.state.related_tags)}
                renderLabel={renderLabel}
                value={this.state.related_tags}
                name='related_tags'
                onChange={this.handleChange}
              />
              <Form.TextArea required label='Description' name='description' placeholder="description..." onChange={this.handleChange} value={this.state.description}/>
              <Form.Input required label='Icon Img' name='icon_url' placeholder="url" onChange={this.handleChange} value={this.state.icon_url}/>
              <Form.Input required label='Banner Img' name='banner_url' placeholder="url" onChange={this.handleChange} value={this.state.banner_url}/>
              <Form.Input required label='Color' name='color' placeholder="#005386" onChange={this.handleChange} value={this.state.color}/>
              <Form.Input required label='Order' name='order' placeholder="0" onChange={this.handleChange} value={this.state.order}/>
              <Form.Group>
                <Form.Button className='mt-7' onClick={this.handleUpdate}><Icon name='edit'/> Edit</Form.Button>
                <Form.Button className='mt-7' onClick={this.handleDelete}
                             negative><Icon name='trash'/> Delete</Form.Button>
              </Form.Group>
            </Form>
            : null
        }
      </div>
    )
  }
}

export default ChKeywordUpdate;