import React, {Component} from "react";
import {Form, Icon} from "semantic-ui-react";
import axios from "axios";

class MkCategoryCreate extends Component {
  state = { relatedTags: [] }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  handleSave = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/market/category`, {
        'name': this.state.name,
        'emoji': this.state.emoji,
      }, {withCredentials: true});
      alert('Success!');
      window.location.href = "/market/category"
    } catch (e) {
      alert("Fail...");
    }
  }

  render() {
    return (
      <div>
        <h3 className='mb-6'>Create Category</h3>
        <Form>
          <Form.Input required label='Emoji' name='emoji' placeholder="emoji" onChange={this.handleChange}/>
          <Form.Input required label='Name' name='name' placeholder="name" onChange={this.handleChange}/>
          <Form.Button className='mt-7' onClick={this.handleSave}><Icon name='save'/> Save</Form.Button>
        </Form>
      </div>
    )
  }
}

export default MkCategoryCreate;