import React, { Component } from 'react';
import { Button, Table } from 'semantic-ui-react'
import axios from 'axios';
import { Link } from 'react-router-dom'

class GroupSubtypeHome extends Component {
  constructor (props) {
    super(props);
    this.state = {
      subtypes: []
    }
  }

  async componentDidMount() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/group/subtype`, {withCredentials: true});
      this.setState({
        subtypes: response.data
      })
    } catch(e) {
      console.log(e);
    }
  }

  render () {
    return (
      <div>
        <div>
          <Link to={'/group/subtype/create'}>
            <Button primary className='mb-5'>Create</Button>
          </Link>
        </div>
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>Id</Table.HeaderCell>
              <Table.HeaderCell width={4}>Name</Table.HeaderCell>
              <Table.HeaderCell width={2}>Type</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {
              this.state.subtypes.map((subtype, idx) =>
                <Table.Row key={idx}>
                  <Table.Cell>{subtype.id}</Table.Cell>
                  <Table.Cell>
                    <Link to={`/group/subtype/update?id=${subtype.id}`}>
                      {subtype.name}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{subtype.parent_type}</Table.Cell>
                </Table.Row>
              )
            }
          </Table.Body>
        </Table>
      </div>
    )
  }
}

export default GroupSubtypeHome;
