import React, { Component } from 'react';
import { Button, Table } from 'semantic-ui-react'
import axios from 'axios';
import moment from "moment";
import { Link } from 'react-router-dom'

class SysNoticeHome extends Component {
  constructor (props) {
    super(props);
    this.state = {
      notices: []
    }
  }

  async componentDidMount() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/system/notice`, {withCredentials: true});
      this.setState({
        notices: response.data
      })
    } catch(e) {
      console.log(e);
    }
  }

  render () {
    return (
      <div>
        <div>
          <Link to={'/system/notice/create'}>
            <Button primary className='mb-5'>Create</Button>
          </Link>
        </div>
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>#</Table.HeaderCell>
              <Table.HeaderCell width={8}>Title</Table.HeaderCell>
              <Table.HeaderCell width={2}>Author</Table.HeaderCell>
              <Table.HeaderCell width={3}>Date</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {
              this.state.notices.map((notice, idx) =>
                <Table.Row key={idx}>
                  <Table.Cell>{idx+1}</Table.Cell>
                  <Table.Cell>
                    <Link to={`/system/notice/update?uuid=${notice.uuid}`}>
                      {notice.title}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{notice.author}</Table.Cell>
                  <Table.Cell>
                    {moment(notice.created_at).format('YYYY.MM.DD. HH:mm')}
                  </Table.Cell>
                </Table.Row>
              )
            }
          </Table.Body>
        </Table>
      </div>
    )
  }
}

export default SysNoticeHome;
