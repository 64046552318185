import React, { Component } from 'react';
import {Button, Card, CardContent, Grid, Table} from 'semantic-ui-react'
import axios from 'axios';
import { Link } from 'react-router-dom'

class ChKeywordHome extends Component {
  constructor (props) {
    super(props);
    this.state = {
      keywords: []
    }
  }

  async componentDidMount() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/keyword`, {withCredentials: true});
      this.setState({
        keywords: response.data
      })
    } catch(e) {
      console.log(e);
    }
  }

  render () {
    return (
      <div>
        <div>
          <Link to={'/channel/keyword/create'}>
            <Button primary className='mb-7'>Create</Button>
          </Link>
        </div>

          <Grid doubling columns={4}>
            {
              this.state.keywords.map((keyword, idx) =>
                <Grid.Column key={idx}>
                    <Link to={`/channel/keyword/update?name=${keyword.name}`}>
                    <div className='p-card pd-1 hover-zoom' style={{background: keyword.color}}>
                      <p className='fs-1 fw-b' style={{color: '#000'}}>{keyword.name}</p>
                      <img alt={keyword.name} style={{maxHeight: '80px', float: 'right'}} src={keyword.icon_url}/>
                    </div>
                    </Link>
                </Grid.Column>
              )
            }
          </Grid>
      </div>
    )
  }
}

export default ChKeywordHome;
