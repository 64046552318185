import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import {Container, Divider, Icon} from 'semantic-ui-react'
import GroupSubtypeHome from "./group.subtype.home";
import GroupSubtypeCreate from "./group.subtype.create";
import GroupSubtypeUpdate from "./group.subtype.update";

class GroupModule extends Component {

  render () {
    return (
      <Container>
        <h2 className='pv-5'><Icon name='group'/> Group Subtype</h2>
        <Switch>
          <Route exact path={'/group/subtype'} component={GroupSubtypeHome}/>
          <Route exact path={'/group/subtype/create'} component={GroupSubtypeCreate}/>
          <Route exact path={'/group/subtype/update'} component={GroupSubtypeUpdate}/>
        </Switch>
      </Container>
    )
  }
}

export default GroupModule;
